import React from 'react';

const stages = ['Pomodoro', 'Short Break', 'Pomodoro', 'Short Break', 'Pomodoro', 'Short Break', 'Pomodoro', 'Long Break'];

const Stages = (props) => {
    return (
        <div id="timer-stages">
            <div className="container">
            {
                stages.map((stage, index) => {
                    let style = 'stage';
                    if (props.currentStage === index) style += ' active';
                    if (index < props.currentStage) style += ' completed';
                    if (index % 2 !== 0) style += ' break';
                    return <button 
                        key={index} 
                        className={style}
                        onClick={() => props.callback(index)}
                    >{stage}</button>
                })
            }
            </div>
        </div>
    );
}

export default Stages;