import React from 'react';

const padZeros = num => num.toString().padStart(2, '0')

const Clock = props => {
    const minutes = Math.floor(props.timeRemaining / 60);
    const seconds = props.timeRemaining % 60;
    const styles = {
        transform: `perspective(999px) rotate(${props.progressbarDegrees}deg)`
    };
    return (
        <div id="clock-container">
            <div id="clock">
                <div id="clock-full" className="clock-mask" style={styles}><div className="clock-fill" style={styles} /></div>
                <div id="clock-half" className="clock-mask"><div className="clock-fill" style={styles} /></div>
                <div id="clock-inner">{padZeros(minutes) + ':' + padZeros(seconds)}</div>
            </div>
        </div>
    );
}

export default Clock;