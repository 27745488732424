import React from 'react';

const ClockButton = (props) => {
    switch (props.type) {
        case 'play':
            return (
                <button className="btn btn-lg btn-outline-primary" onClick={props.callback}>
                    <i className="fa fa-play" aria-hidden="true"></i> Start
                </button>
            );
        case 'pause':
            return (
                <button className="btn btn-lg btn-outline-warning" onClick={props.callback}>
                    <i className="fa fa-pause" aria-hidden="true"></i> Pause
                </button>
            );
        default:
            break;
    }
} 

export default ClockButton;