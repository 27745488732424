import React from 'react';

import Clock from './Clock';
import ClockButton from './ClockButton';
import Stages from './Stages';

const calculateDegrees = (remaining, total) => {
    const percentage = ((total - remaining) / total) * 100;
    const degrees = percentage * 180 / 100;
    return degrees;
}

export default class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStage: 0,
            currentStageTime: props.workTime, // time for current stage (work or rest)
            timeRemaining: props.workTime, // time remaining in the current clock
            progressbarDegrees: 0,
            isRunning: false
        }
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.workTime !== this.props.workTime) || (prevProps.shortBreakTime !== this.props.shortBreakTime) || (prevProps.longBreakTime !== this.props.longBreakTime)) {
            this.handleStageSwitch(this.state.currentStage);
        }
    }

    handleStageSwitch = (index) => {
        const nextStage = (index || index === 0) ? index : this.state.currentStage + 1;
        // switch to work stage
        if (nextStage % 2 === 0) {
            this.setState({
                currentStage: nextStage,
                currentStageTime: this.props.workTime,
                timeRemaining: this.props.workTime,
                isRunning: false,
                progressbarDegrees: 0
            });
            document.body.classList.remove('break-time'); 
            document.body.classList.add('work-time');
        }
        // switch to break stage
        else {
            const breakTime = nextStage === 7 ? this.props.longBreakTime : this.props.shortBreakTime;
            this.setState({
                currentStage: nextStage,
                currentStageTime: breakTime,
                timeRemaining: breakTime,
                isRunning: false,
                progressbarDegrees: 0
            });
            document.body.classList.remove('work-time'); 
            document.body.classList.add('break-time');
        }
        if (this.timerID) clearInterval(this.timerID);
    }

    tick() {
        const newTimeRemaining = this.state.timeRemaining - 1;
        if (newTimeRemaining <= 0) {
            this.handleStageSwitch();
            this.props.onTimerFinishes();
        }
        else {
            this.setState({
                timeRemaining: newTimeRemaining,
                progressbarDegrees: calculateDegrees(newTimeRemaining, this.state.currentStageTime) 
            });
        }
    }

    start() {
        this.setState({isRunning: true});
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    pause() {
        this.setState({isRunning: false});
        clearInterval(this.timerID);
    }

    toggleTimer = () => {
        if (this.state.isRunning)
            this.pause();
        else
            this.start();
    }

    render() {
        return (
            <>
            <div id="timer-container">
                <Clock currentStage={this.state.currentStage} timeRemaining={this.state.timeRemaining} progressbarDegrees={this.state.progressbarDegrees} />
                <div id="timer-buttons-container">
                    <ClockButton type={this.state.isRunning ? 'pause' : 'play'} callback={this.toggleTimer} />
                </div>
            </div>
            <Stages currentStage={this.state.currentStage} callback={this.handleStageSwitch} />
            </>
        );
    }
}