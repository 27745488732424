import React from 'react';
import Notification from 'react-web-notification';

import Timer from './components/Timer';
import SettingsModal from './components/SettingsModal';
import { soundList } from './soundList';

const DEFAULT_POMODORO_MINS = 25;
const DEFAULT_SHORT_BREAK_MINS = 5;
const DEFAULT_LONG_BREAK_MINS = 15;
const storageName = 'settingsPomodoroo';

const minToSec = mins => mins * 60;

export default class App extends React.Component {
    constructor(props) {
        super(props);
        let storage = localStorage.getItem(storageName);
        try {
            storage = JSON.parse(storage);
        } catch (e) {
            storage = null;
        }
        // load localStorage into this.state and overwrite Notification status
        if (storage !== null) {
            if ((('Notification' in window) && window.Notification)) {
                storage.supportedNotifications = true;
                storage.showNotifications = window.Notification.permission === 'default' ? false : storage.showNotifications;
                storage.grantedNotifications = window.Notification.permission !== 'denied';
            }
            else {
                storage.supportedNotifications = false;
                storage.showNotifications = false;
                storage.grantedNotifications = false;
            }
            this.state = storage;
        }
        // not localStorage? default this.state
        else
            this.state = {
                workTime: DEFAULT_POMODORO_MINS,
                shortBreakTime: DEFAULT_SHORT_BREAK_MINS,
                longBreakTime: DEFAULT_LONG_BREAK_MINS,
                showModal: false,
                showNotifications: false,
                grantedNotifications: true,
                supportedNotifications: true,
                chosenSound: 1,
                notificationTitle: '',
                notificationOptions: {},
                darkMode: false
            }
        if (this.state.darkMode) document.body.classList.add('dark-mode'); else document.body.classList.remove('dark-mode');
        this.notificationAudio = new Audio('./assets/sounds/' + soundList[this.state.chosenSound][1])
    }

    saveStateAndStorage(newState) {
        this.setState(newState, () => {
            localStorage.setItem(storageName, JSON.stringify(this.state));
        });
    }

    // modal settings methods

    handleShowModal = () => {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    handleSaveSettings = (data) => {
        this.notificationAudio = new Audio('./assets/sounds/' + soundList[data.chosenSound][1]);
        if (data.darkMode) document.body.classList.add('dark-mode'); else document.body.classList.remove('dark-mode');
        this.saveStateAndStorage(data);
    }

    // notifications methods

    handleNotSupported = () => {
        this.saveStateAndStorage({
            showNotifications: false,
            grantedNotifications: false,
            supportedNotifications: false
        });
    }

    handlePermissionGranted = () => {
        this.saveStateAndStorage({
            showNotifications: true,
            grantedNotifications: true
        });
    }

    handlePermissionDenied = () => {
        this.saveStateAndStorage({
            showNotifications: true,
            grantedNotifications: false
        });
    }

    handleNotificationOnShow = () => {
        this.setState({
            notificationTitle: '',
            notificationOptions: {}
        });
    }

    // app methods

    handleOnTimerFinishes = () => {
        console.log('TIMER ENDS!!');
        if (this.state.showNotifications && this.state.grantedNotifications) {
            this.setState({
                notificationTitle: 'Ha terminado el contador',
                notificationOptions: {
                    tag: Date.now(),
                    body: 'Work time is over. Time for a break!'
                }
            });
        }
        this.notificationAudio.play();
    }

    notif = () => {
        this.handleOnTimerFinishes();
    }

    render() {
        return (
            <>
                <header id="header">
                    <div className="container">
                        <h1>Pomodoroo</h1>
                        <button className="btn ml-auto" onClick={this.handleShowModal}><i className="fa fa-cog"></i> Settings</button>
                        {/*<button onClick={this.notif}>Notif</button> */}
                    </div>
                </header>
                <Timer
                    workTime={minToSec(this.state.workTime)}
                    shortBreakTime={minToSec(this.state.shortBreakTime)}
                    longBreakTime={minToSec(this.state.longBreakTime)}
                    onTimerFinishes={this.handleOnTimerFinishes}
                />
                {this.state.showModal ?
                    <SettingsModal
                        {...this.state} soundList={soundList}
                        saveSettings={this.handleSaveSettings}
                        closeFunction={this.handleCloseModal}
                    /> : null
                }
                {this.state.showNotifications ?
                    <Notification
                        title={this.state.notificationTitle}
                        options={this.state.notificationOptions}
                        timeout={0}
                        askAgain={false}
                        notSupported={this.handleNotSupported}
                        onPermissionGranted={this.handlePermissionGranted}
                        onPermissionDenied={this.handlePermissionDenied}
                        onShow={this.handleNotificationOnShow}
                    /> : null
                }
            </>
        );
    }
}