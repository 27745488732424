import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App.js';

ReactDOM.render(
  <App />,
  document.getElementById('app-container')
);
