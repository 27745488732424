import React from 'react';
import { Button, Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';

export default class SettingsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workTimeInput: props.workTime,
            shortBreakTimeInput: props.shortBreakTime,
            longBreakTimeInput: props.longBreakTime,
            chosenSound: props.chosenSound,
            showNotifications: props.showNotifications,
            darkMode: props.darkMode
        };
        this.warningText = '';
        if (!this.props.supportedNotifications) 
            this.warningText = 'Web notifications are not supported in this browser.';
        else if (!this.props.grantedNotifications)
            this.warningText = 'Web notifications are blocked. Please, enable them in your browser.';
    }

    handleInputChange = (event) => {
        let inputValue = null;
        switch (event.target.type) {
            case 'checkbox':
                inputValue = event.target.checked;
                break;
            default:
                inputValue = event.target.value;
        }
        this.setState({[event.target.id]: inputValue});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.currentTarget.checkValidity() === true) {
            this.props.saveSettings({
                workTime: this.state.workTimeInput,
                shortBreakTime: this.state.shortBreakTimeInput,
                longBreakTime: this.state.longBreakTimeInput,
                chosenSound: this.state.chosenSound,
                showNotifications: this.state.showNotifications,
                darkMode: this.state.darkMode,
                showModal: false
            });
        }
    }

    testSound = () => {
        const sound = new Audio('./assets/sounds/' + this.props.soundList[this.state.chosenSound][1]);
        sound.play();
    }
    
    render() {
        return (
            <>
                <Modal id="modal-settings" show={this.props.showModal} onHide={() => this.props.closeFunction()} >
                    <Modal.Header closeButton>
                        <Modal.Title><i className="fa fa-cog"></i> Settings</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={this.handleSubmit}>
                        <Modal.Body>
                            <Form.Group as={Row} controlId="workTimeInput">
                                <Form.Label column xs={7}>Pomodoro (minutes)</Form.Label>
                                <Col xs={5}>
                                    <Form.Control size="sm" type="number" min="1" value={this.state.workTimeInput} onChange={this.handleInputChange} required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="shortBreakTimeInput">
                                <Form.Label column xs={7}>Short Break (minutes)</Form.Label>
                                <Col xs={5}>
                                    <Form.Control size="sm" type="number" min="1" value={this.state.shortBreakTimeInput} onChange={this.handleInputChange} required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="longBreakTimeInput">
                                <Form.Label column xs={7}>Long Break (minutes)</Form.Label>
                                <Col xs={5}>
                                    <Form.Control size="sm" type="number" min="1" value={this.state.longBreakTimeInput} onChange={this.handleInputChange} required />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="chosenSound">
                                <Form.Label column xs={6} sm={7}>Sound notification</Form.Label>
                                <Col xs={6} sm={5}>
                                    <InputGroup className="">
                                        <Form.Control as="select" size="sm" value={this.state.chosenSound} onChange={this.handleInputChange} custom>
                                        {
                                            Object.entries(this.props.soundList).map((v, k) =>
                                                <option key={k} value={v[0]}>{v[1][0]}</option>
                                            )
                                        }
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <Button variant="outline-primary" size="sm" onClick={this.testSound}><i className="fa fa-volume-up"></i></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="showNotifications">
                                <Form.Label column xs={7}>Web notifications</Form.Label>
                                <Col xs={5}>
                                    <Form.Check 
                                        type="switch" 
                                        label="" 
                                        disabled={!this.props.grantedNotifications || !this.props.supportedNotifications} 
                                        checked={this.state.showNotifications && this.props.grantedNotifications && this.props.supportedNotifications} 
                                        onChange={this.handleInputChange} 
                                    />
                                </Col>
                                <Col xs={12}>
                                    <Form.Text className="text-danger">{this.warningText}</Form.Text>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="darkMode">
                                <Form.Label column xs={7}>Dark Mode</Form.Label>
                                <Col xs={5}>
                                    <Form.Check 
                                        type="switch" 
                                        label="" 
                                        checked={this.state.darkMode} 
                                        onChange={this.handleInputChange} 
                                    />
                                </Col>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="link text-danger" onClick={() => this.props.closeFunction()}>Close</Button>
                            <Button variant="success" type="submit">Save</Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
        );
    }
}
